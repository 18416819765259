@so-plan-banner-cls: ~'so-plan-banner';

.@{so-plan-banner-cls} {
    position: relative;
    margin-bottom: 16px;

    .ant-alert {
        min-height: 56px;
    }
}
