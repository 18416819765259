@maskFillColor: transparent;

.reac-tour-custom-mask {
    rect[mask="url(#custom-mask-main)"] {
        fill: @maskFillColor !important;
    }
    rect[clip-path="url(#custom-clip-path)"] {
        fill: @maskFillColor !important;
    }
}
