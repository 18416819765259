.time-range-select {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }

    &__wrapper {
        display: flex;

        &.horizontal {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        &.vertical {
            flex-direction: column;
        }
    }

    .time-zone-label {
        margin-bottom: 2px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 16px;
    }

    .border-line {
        display: inline-block;
        width: 12px;
        min-width: 12px;
        height: 1px;
        margin: 0 8px;
        line-height: 32px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.87);
    }
}
