@prefix: ~'ww-open-data';

.@{prefix} {
    &-ellipsis {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    &-hide {
        display: none;
    }
}
