@import '~@yeastar/pc-web-ui/src/style/variables.less';

.rate-character {
    position: relative;
    margin: 16px 0;
    padding-bottom: 24px;
    white-space: nowrap;

    &__wrapper {
        display: inline-block;
    }

    &__tip {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        &-wrapper {
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
        }
    }

    &__icon-wrapper {
        display: inline-block;
        cursor: pointer;
    }

    &__icon {
        display: inline-block;
        width: 56px;
        height: 56px;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: middle;
        text-rendering: optimizeLegibility;
        transition: transform 0.2s ease-in-out;

        @media (any-hover: hover) {
            &:hover {
                transform: scale(64/56);
            }
        }
    }
}
