.so-empty-result {
    .so-empty-image {
        > img {
            user-select: none;
            -webkit-user-drag: none;
        }
    }

    .ant-result-subtitle {
        line-height: 24px;
    }
}
