.cycle-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 9px 16px 11px;
    color: #006EE1;
    background-color: #E6F6FF;
    border-radius: 4px;

    span {
        font-size: 14px;
        line-height: 20px;
    }
}
