@so-plan-alert-cls: ~'so-plan-alert';

.@{so-plan-alert-cls} {
    position: relative;
    padding: 9px 16px;
    display: flex;
    align-items: center;

    &-content {
        flex: 1;
        display: flex;
        justify-content: center;
        line-height: 22px;
    }

    &-mobile {
        .@{so-plan-alert-cls}-content {
            justify-content: flex-start;
        }
    }

    &-tip-icon {
        margin-right: 4px;
        vertical-align: -4px;
    }

    &-close-icon {
        width: 22px;
        height: 22px;
        text-align: center;
        cursor: pointer;
    }

    &-info {
        background-color: #e6f6ff;

        .@{so-plan-alert-cls}-tip-icon {
            color: #006ee1;
        }
    }

    &-error {
        background-color: #fff2f0;

        .@{so-plan-alert-cls}-tip-icon {
            color: #f63b3b;
        }
    }

    &-success {
        background-color: #f0fff3;

        .@{so-plan-alert-cls}-tip-icon {
            color: #20c161;
        }
    }

    &-warning {
        background-color: #fff8e6;

        .@{so-plan-alert-cls}-tip-icon {
            color: #fe8c00;
        }
    }
}
