.so-plan-lock{
    color: rgba(0, 0, 0, 0.24);

    &-icon {
        padding: 4px;
        color: rgba(0, 0, 0, 0.87);
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.07);
        }
    }
}
