@import '~@yeastar/pc-web-ui/src/style/variables.less';

.feedback-result-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    :global(.ant-empty) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 62%;
        margin: 0 32px;
    }

    :global(.ant-empty-image) {
        margin-bottom: 24px;
    }

    .feedback-result-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38%;
        :global(.ant-btn) {
            width: 160px;
        }
    }

    .feedback-result {
        &-title {
            margin: 0 0 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }
        &-description,
        &-tip {
            color: @gray-5;
            font-size: @font-size-base;
            line-height: 20px;
        }
    }

    :global(.feedback-result-countdown) {
        color: @blue-base;
    }
}
