@tourZIndex: 999;
@maskFillColor: transparent;
@tourPopoverColor: #268ded;

#___reactour {
    > div {
        z-index: @tourZIndex;
    }
    .reac-tour-popover {
        z-index: @tourZIndex;
        padding: 0;
        color: #fff !important;
        background-color: @tourPopoverColor;
        border-radius: 12px;
        box-shadow: none;
        user-select: none;
        .tour-popover-arrow {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-bottom-color: @tourPopoverColor;
        }
    }
    .reac-tour-mask {
        display: none;
    }
}
